.LandingPage .icon {
  width: 3em;
  height: 3em;
}

.LandingPage .Layer {
  margin-top: -2rem;
  ;
}

h2, .h2 {
  margin-bottom: 0;
}