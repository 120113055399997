.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-Container {
  background-image: url(../img/printomio-bg.jpg);
  background-repeat: none;
  background-size: cover;
  padding: 0;
  max-width: 576px;
  min-height: 100vh;
}

.App-Container .Layer {
  background-image: linear-gradient(rgba(255, 255, 255, 0.0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1.0), rgba(255, 255, 255, 0));
  padding: 2rem 0;
}

.btn-primary {
  background-color: rgb(233, 102, 47);
  border-color: rgb(233, 102, 47);
  color: rgba(255, 255, 255, 1);
}

.btn-primary:hover, .btn-primary.active {
  background-color: rgb(198, 87, 40);
  border-color: rgb(198, 87, 40);
  color: rgba(255, 255, 255, 1);
}

.btn-primary:disabled {
  background-color: #aaa;
  border-color: #000;
  color: #000;
}


.form-control:focus, .custom-select.is-valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .was-validated .form-control:valid:focus, .custom-control-input.is-valid:focus~.custom-control-label::before, .was-validated .custom-control-input:valid:focus~.custom-control-label::before, .custom-file-input.is-valid:focus~.custom-file-label, .was-validated .custom-file-input:valid:focus~.custom-file-label, .custom-select.is-invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .was-validated .form-control:invalid:focus, .custom-control-input.is-invalid:focus~.custom-control-label::before, .was-validated .custom-control-input:invalid:focus~.custom-control-label::before, .custom-file-input.is-invalid:focus~.custom-file-label, .was-validated .custom-file-input:invalid:focus~.custom-file-label, .btn.focus, .btn:focus, .btn-primary.focus, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus, .custom-control-input:focus~.custom-control-label::before, .custom-select:focus, .custom-file-input:focus~.custom-file-label, .custom-checkbox:focus, .custom-radio:focus, .custom-control:focus, .form-control:focus, .custom-select:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 102, 47, 0.2);
  border: 1px solid rgba(233, 102, 47, 0.5);
}

.btn-secondary {
  background-color: rgba(255, 255, 255, 1);
  border-color: rgb(233, 102, 47);
  color: rgb(233, 102, 47);
}

.btn-secondary:hover, .btn-secondary.active {
  background-color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 1);
  color: rgb(233, 102, 47);
}

a {
  color: rgb(233, 102, 47);
  text-decoration: none;
}

a:hover, a.active {
  color: rgb(198, 87, 40);
}

.App-Container .card {
  border-color: rgb(241, 156, 84);
}

.App-Container .card-header {
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(241, 156, 84);
  padding: 0.25rem 0.5rem;
}

.App-Container .card-header h3 {
  margin: 0;
}

.App-Container .hidden {
  display: none !important;
}

.App-Container .float-right {
  float: right;
}

.App-Container .float-left {
  float: left;
}

.form-check-input[type="radio"]:checked+label .card {
  background-color: rgb(233, 102, 47);
  color: #fff;
}

.clearboth {
  clear: both
}

.float-right {
  float: right;
}

.overflow-ellipsis {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.App-TextHighlight {
  color: rgb(233, 102, 47);
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em;
  color: #000;
  background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  opacity: .5;
}