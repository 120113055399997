.dropzone {
	border-style: dotted;
	border-color: rgb(233, 102, 47);
	padding: 15px;
}

.img.size100 {
	max-width: 100%;
	height: auto;
}

.sfuwp_uploadcard {
	height: auto !important;
	min-height: 156px;
}

.sfuwp_uploadcard .card-body {
	padding: 0;
}

h1, .h1 {
	color: #000;
	font-weight: bold;
}

h3, .h3 {
	font-weight: bold;
}

.progress {
	border-radius: 0.5rem;
	height: 0.8rem;

}

.progress-bar {
	background-color: #C6E31C;
	color: #000;
}

.form-check-input:checked {
	background-color: rgb(233, 102, 47);
	border-color: rgb(233, 102, 47);
}

.form-check-input:focus {
	box-shadow: none;
}

.progressTitle {
	text-align: center;
	font-size: 10px;
	width: 100%;
	display: block;
}

.progressTitle.active {
	color: rgb(233, 102, 47);
}

.imageTile {
	position: relative;
	padding: 1px;
}

.voting {
	position: absolute;
	bottom: 2px;
	left: 2px;
	font-size: 2rem;
	color: #fff;
}

.voting:hover {
	color: rgb(233, 102, 47) !important;
}

.file-upload-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 5px 0;
}

.circular-fit {
	flex: 0 0 70px;
	display: inline-block;
	position: relative;
	width: 70px;
	height: 70px;
	overflow: hidden;
	border-radius: 50%;
}

.circular-fit img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.progress-section {
	flex: 1 1 auto;
	padding: 0 10px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.progress-section>.file-label {
	display: flex;
	white-space: nowrap;
}

.progress-section>.file-label>.file-name {
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}

.progress-section>.progress {
	background-color: lightgray;
}

.right-control-section {
	flex: 0 0 50px;
}

.right-control-section>svg {
	width: 30px;
	height: 30px;
}

.alert-upload-error {
	display: flex;
	justify-content: space-between;
}

.alert-upload-error>error-messages {
	flex: 1 1 auto;
}

.checkcircle-green {
	color: #C6E31C;
}

.upload-failed>.progress-bar {
	background-color: orangered;
}

.fixcircle-error {
	color: orangered;
}