.ProjectAdd .productInfo {
    color: transparent;
    text-shadow: 0px 0px 1px rgb(233, 102, 47);
    cursor: pointer;
}

.ProjectAdd .productInfo .typeSelect {
    color: rgb(233, 102, 47);
    text-shadow: none;
    font-size: 36px;
    margin-bottom: 5px;
}

.ProjectAdd .form-check-input[type="radio"]:checked+label .productInfo {
    background-color: rgb(233, 102, 47);
    color: transparent;
    text-shadow: 0px 0px 1px #fff;
    cursor: pointer;
}

.ProjectAdd .form-check-input[type="radio"]:checked+label .productInfo .typeSelect {
    color: #fff;
    text-shadow: none;
}

.ProjectAdd .product {
    background-size: cover;
    min-height: 150px;
}

.ProjectAdd .row.product {
    border: 1px solid rgb(233, 102, 47);
    background-color: #fff;
    margin: 5px;
    text-align: center;
}

.ProjectAdd .row.product .productInfo {
    padding: 20px 5px;
}

.ProjectAdd .product-DwCJuBy5Zn {
    background-image: url("../img/DwCJuBy5Zn.jpg");
}

.ProjectAdd .product-xTNT6aRxlJ {
    background-image: url("../img/xTNT6aRxlJ.jpg");
}