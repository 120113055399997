.dashboard-new {
    background-image: url('../img/dashboard-new-project.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 25vw;
    max-height: 250px;;
    position: relative;
}

.dashboard-new .new.btn{
    position: absolute;
    bottom: 10px;
    right: 20px;
}
.dashboard-new .info.btn{
    position: absolute;
    top: 10px;
    right: 20px;
}
