.img-responsive {
    max-width: 100%;
}

h2, .h2 {
    color: #000;
}

.App-Container a.backLink {
    color: #000;
}

.imageList {
    margin: 40px -20px 0;
}