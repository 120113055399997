.projectItem {
    border: 1px solid rgb(233, 102, 47);
    background-color: #fff;
    margin-bottom: 10px;
}

.projectItem .productImage {
    background-size: cover;
    min-height: 150px;
}

.projectItem .productImage-DwCJuBy5Zn {
    background-image: url("../img/DwCJuBy5Zn.jpg");
}

.projectItem .productImage-xTNT6aRxlJ {
    background-image: url("../img/xTNT6aRxlJ.jpg");
}

.projectItem .productImage a {
    display: block;
    width: 100%;
    height: 100%;
}

.projectItem .projectInfo {
    padding: 10px;
}

.ProjectItem-Status {
    font-size: 0.9rem;
}